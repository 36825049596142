import React from 'react';
import {
  ConditionsCollapsible,
  ContinuedTextListModule,
  FeaturedArticles,
  Hero,
  Module,
  SingleQuote,
  StatisticStructuredBox,
  Structured
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import { Layout } from '../../redesign';

import heroContent from '../../redesign/content/hormonal-health/hormonal-hero.json';
import singleStatStructuredContent from '../../redesign/content/hormonal-health/single-stat-structure.json';
import symptomsListContent from '../../redesign/content/hormonal-health/symptoms-list.json';
import doctorsSuccessfullyTreatContent from '../../redesign/content/hormonal-health/doctors-successfully-treat.json';
import structuredContent from '../../redesign/content/hormonal-health/structured-understand';
import quoteCarouselContent from '../../redesign/content/hormonal-health/quote-carousel.json';
import blogPostContent from '../../redesign/content/hormonal-health/blog-post.json';
import { default as SEO } from '../../components/SEO';

export default function HormonalHealth() {
  return (
    <Layout>
      <SEO
        pageTitle="Hormonal Imbalance Natural Treatment | Holistic Care | Parsley Health"
        pageDescription="Benefit from a whole body approach to your hormonal health. At Parsley Health, we use a natural, holistic treatment to hormonal imbalance in women."
      />
      <Module>
        <Hero {...heroContent} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...singleStatStructuredContent} />
      </Module>
      <Module>
        <ContinuedTextListModule {...symptomsListContent} />
      </Module>
      <Module>
        <ConditionsCollapsible {...doctorsSuccessfullyTreatContent} />
      </Module>
      <Module>
        <Structured {...structuredContent} />
      </Module>
      <Module>
        <SingleQuote {...quoteCarouselContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Hormonal Health page - Stress-free online holistic care module"
          eyebrow="How to balance hormones"
        />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
