import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'Understand your hormone health and heal.',
  description:
    'When one hormone is thrown off, another can easily follow. This is why Parsley’s holistic approach is so effective: We uncover the full picture of your health to get you in balance.',
  items: [
    {
      id: 'hormonal-screening',
      heading: 'Hormonal screening',
      description:
        'Most traditional doctors won’t prescribe advanced testing. We prescribe panels like the DUTCH test that show what your hormone balance looks like at any given moment.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'whole-body-approach',
      heading: 'A whole-body approach',
      description:
        'Your personalized treatment plan includes gut health optimization, supplements, changes to sleep, stress reduction, movement, and nutrition.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'personalized-ongoing-support',
      heading: 'Personalized, ongoing support',
      description:
        'Your Care Team includes a health coach dedicated to guiding and supporting you in making lifestyle changes that can have a powerful impact on your hormones.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
